{
  "home": "Accueil",
  "vote": "Voter",
  "support": "Support",
  "addbot": "Ajouter le bot",
  "status": "Status du bot",
  "fonctions": "Fonctionnalitées",
  "dash": "Dashboard",
  "title2": "Un nouveau bot !",
  "desc1": "Plus vite, plus léger et plus efficace !",
  "desc2": "Bonjour, tu crées un serveur Discord mais tu as des dizaines de bots ? Yura est la solution ! Pleins fonctionnalités de bot populaires sont présents en un seul bot ! \nYura est un bot discord qui a comme but d'être le robot idéal pour remplacer les bots populaires ! Vous y retrouverez toutes les choses dont vous avez besoin !",
  "functionalities": "Les Fonctionnalitées :",
  "configadvance": "Configuration avancé !",
  "configadvance_desc": "Vous pouvez configurer le bot comme par exemple le prefix, l'ainti-raid, l'autorole, les messages de bienvenue et de départ !",
  "backup": " Système de Backup :",
  "backup_desc": "Vous pouvez crée des backups de votre serveur pour être sur de ne jamais le perdre ! Vous pouvez aussi cloner votre serveur sur un autre !",
  "giveaway": " Giveaways :",
  "giveaway_desc": "Un système de giveaway qui permet de crée des tirages au sort avec un prix, un temps et un nombre de gagnants custom ! Il y a même un système de drop !",
  "modadvance": " Modérations avancé :",
  "modadvance_desc": " Le bot contiennent plusieurs commandes pour faire la modération sur votre serveur ! ",
  "automod": " Auto Modérations :",
  "automod_desc": "Vous pouvez configurer le bot pour faire de l'auto modération ! Par exemple bloquer les liens.",
  "music": " Musique :",
  "music_desc": " Une fonctionnalité qui permet de jouer de la musique dans un salon vocal (Youtube, spotify et même soundcloud) !",
  "raidprotection": " Protection contre le spam et le raid !",
  "raidprotection_desc": "Le bot fournit des protections contre le spam, le raid et contre toutes attaques malveillantes sur votre serveur !",
  "util": " Utilitaire :",
  "util_desc": "Il y a une cathégorie sur le bot, Utilitaire qui peux vous aider pour certaines taches ou pour s'informer !",
  "img": "Images modifications :",
  "img_desc": "Le bot contient plusieurs commandes qui permettent de rajouter des filtres sur vos photo de profil !",
  "ticket": " Système de ticket !",
  "ticket_desc": "Le bot contient un système de ticket pour votre serveur ! C'est un système avec des réactions.",
  "suggest": " Système de Suggestions !",
  "suggest_desc": " Dans la cathégorie utilitaire il y a un système qui permet de crée des suggestions dans un salon !",
  "fun": " Du Fun !",
  "fun_desc": "Il y a une autre cathégorie sur le bot, Fun qui permet de vous amuser entre vos amis !",
  "theme_light": "Thème lumineux !",
  "theme_dark": "Thème sombre !"
}