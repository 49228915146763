{
  "home": "Home",
  "vote": "Vote",
  "support": "Support",
  "addbot": "Add to Discord",
  "status": "Status",
  "fonctions": "Features",
  "dash": "Dashboard",
  "title2": "A new bot !",
  "desc1": "Faster, lighter and more efficient !",
  "desc2": "Hello, you create a Discord server but you have dozens of bots? Yura is the solution! Full features of popular bots are present in one single bot! \nYura is a discord bot that aims to be the ideal robot to replace popular bots! You'll find all the things you need!",
  "functionalities": "Functionalities :",
  "configadvance": " Advanced configuration :",
  "configadvance_desc": "You can configure the bot like prefix, ainti-raid, autorole, welcome and departure messages!",
  "backup": " Backup System :",
  "backup_desc": "You can create backups of your server to make sure you never lose it! You can also clone your server on another one !",
  "giveaway": " Giveaways :",
  "giveaway_desc": "A giveaway system that allows you to create draws with a custom prize, time and number of winners! There is even a drop system!",
  "modadvance": " Advanced moderation :",
  "modadvance_desc": " The bot include many commands to do moderation on your server!",
  "automod": " Auto Moderations :",
  "automod_desc": "You can configure the bot to do auto moderation! For example to block links.",
  "music": " Music :",
  "music_desc": " A feature that allows you to play music in a vocal salon (Youtube, spotify and even soundcloud)!",
  "raidprotection": " Spam and raid protection!",
  "raidprotection_desc": "The bot provides protection from spam, raid and all malicious attacks on your server!",
  "util": " Util :",
  "util_desc": "There is a category on the bot, Utility that can help you for certain stains or for information!",
  "img": "Images modifications :",
  "img_desc": "The bot includes many commands that allow you to add filters on your profile pictures!",
  "ticket": " Ticket system!",
  "ticket_desc": "The bot contains a ticket system for your server! It's a system with reactions.",
  "suggest": " Suggestion System!",
  "suggest_desc": " In the utility category there is a system that allows you to create suggestions in a salon!",
  "fun": " Fun!",
  "fun_desc": "There is another cathegory on the bot, Fun which allows you to have fun with your friends!",
  "theme_light": "Bright theme!",
  "theme_dark": "Dark theme!"
}