{
  "addbot": "Invitar Yura",
  "automod": " Moderación automática :",
  "automod_desc": "Puedes configurar el bot para que haga una moderación automática. Por ejemplo, para bloquear enlaces.",
  "backup": " Sistema de respaldo :",
  "backup_desc": "Puedes crear copias de seguridad de tu servidor para asegurarte de no perderlo nunca. ¡También puedes clonar tu servidor en otro !",
  "configadvance": " Configuración avanzada :",
  "configadvance_desc": "¡Puedes configurar el bot como prefijo, ainti-raid, autorole, mensajes de bienvenida y de salida!",
  "dash": "Dashboard",
  "desc1": "¡ Más rápido, más ligero y más eficiente !",
  "desc2": "Hola, ¿creas un servidor de Discord pero tienes decenas de bots? ¡Yura es la solución! ¡Todas las características de los bots populares están presentes en un solo bot! \n¡Yura es un bot de discordia que pretende ser el robot ideal para sustituir a los bots populares! ¡Encontrarás todo lo que necesitas!",
  "fonctions": "Características",
  "fun": " ¡Diversión!",
  "fun_desc": "Hay otra categoría en el bot, Fun, que te permite divertirte con tus amigos.",
  "functionalities": "Funcionalidades :",
  "giveaway": " Giveaways :",
  "giveaway_desc": "Un sistema de regalos que permite crear sorteos con un premio, tiempo y número de ganadores personalizados. ¡Incluso hay un sistema de entrega de premios!",
  "home": "Inicio",
  "img": "Modificación de las imágenes :",
  "img_desc": "El bot incluye muchos comandos que te permiten añadir filtros en tus fotos de perfil.",
  "modadvance": " Moderación avanzada :",
  "modadvance_desc": " ¡El bot incluye muchos comandos para hacer la moderación en su servidor!",
  "music": "Música :",
  "music_desc": " Una función que permite reproducir música en un salón vocal (Youtube, Spotify e incluso Soundcloud).",
  "raidprotection": "Protección contra el spam y las incursiones.",
  "raidprotection_desc": "El bot proporciona protección contra el spam, el raid y todos los ataques maliciosos a su servidor.",
  "status": "Estado",
  "suggest": " Sistema de sugerencias",
  "suggest_desc": " En la categoría de utilidad hay un sistema que permite crear sugerencias en un salón.",
  "support": "Soporte",
  "theme_dark": "¡Tema oscuro !",
  "theme_light": "¡Tema brillante !",
  "ticket": "Sistema de tickets.",
  "ticket_desc": "El bot contiene un sistema de tickets para su servidor. Es un sistema con reacciones.",
  "title2": "¡ Un nuevo bot !",
  "util": " Utilidad :",
  "util_desc": "¡Hay una categoría en el bot, Utilidad que puede ayudarle para ciertas manchas o para información!",
  "vote": "Votar"
}